import React, { memo, type ReactElement } from 'react';
import { useIntl } from 'react-intl-next';
import type { MessageDescriptor } from 'react-intl-next';

import type { NewCoreIconProps } from '@atlaskit/icon';
import HomeIcon from '@atlaskit/icon/core/home';
import OfficeBuildingIcon from '@atlaskit/icon/core/office-building';
import ClockIcon from '@atlaskit/icon/core/clock';
import StarUnstarredIcon from '@atlaskit/icon/core/star-unstarred';
import GlobeIcon from '@atlaskit/icon/core/globe';
import AppsIcon from '@atlaskit/icon/core/apps';
import GoalIcon from '@atlaskit/icon/core/goal';
import HashtagIcon from '@atlaskit/icon/core/hashtag';
import TeamsIcon from '@atlaskit/icon/core/teams';
import EditIcon from '@atlaskit/icon/core/edit';
import TaskIcon from '@atlaskit/icon/core/task';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import FilesIcon from '@atlaskit/icon/core/files';
import DataFlowIcon from '@atlaskit/icon/core/data-flow';
import CustomizeIcon from '@atlaskit/icon/core/customize';

import { i18n } from './globalNavigationTranslations';
import type { menuId } from './useMenuItems';

type TranslatedComponentProps = {
	icon: { (props: NewCoreIconProps, displayName: string) };
	i18n: MessageDescriptor;
};

const TranslatedIconComponent = memo(({ icon, i18n }: TranslatedComponentProps) => {
	const { formatMessage } = useIntl();
	const translatedLabel = formatMessage(i18n);
	const IconComponent = icon;
	return <IconComponent label={translatedLabel} color="currentColor" spacing="spacious" />;
});

//Note DST is planning to simplify how icons are passed to Nav 4 icons, & this boilerplate may not always be needed
//these are not behind loadables to prevent a delay in showing the icons. If there is performance regression they could be put behind loadables
export const HomeIconComponent = <TranslatedIconComponent icon={HomeIcon} i18n={i18n.yourWork} />;
export const OfficeIconComponent = (
	<TranslatedIconComponent icon={OfficeBuildingIcon} i18n={i18n.companyHub} />
);
export const ClockIconComponent = <TranslatedIconComponent icon={ClockIcon} i18n={i18n.recent} />;
export const StarIconComponent = (
	<TranslatedIconComponent icon={StarUnstarredIcon} i18n={i18n.starred} />
);
export const GlobeIconComponent = <TranslatedIconComponent icon={GlobeIcon} i18n={i18n.spaces} />;
export const AppsIconComponent = <TranslatedIconComponent icon={AppsIcon} i18n={i18n.apps} />;
export const GoalIconComponent = <TranslatedIconComponent icon={GoalIcon} i18n={i18n.goals} />;
export const HashIconComponent = <TranslatedIconComponent icon={HashtagIcon} i18n={i18n.topics} />;
export const TeamsIconComponent = <TranslatedIconComponent icon={TeamsIcon} i18n={i18n.teams} />;
export const EditIconComponent = <TranslatedIconComponent icon={EditIcon} i18n={i18n.drafts} />;
export const TaskIconComponent = <TranslatedIconComponent icon={TaskIcon} i18n={i18n.tasks} />;
export const TemplatesIconComponent = (
	<TranslatedIconComponent icon={FilesIcon} i18n={i18n.templates} />
);
export const MoreIconComponent = (
	<TranslatedIconComponent icon={ShowMoreHorizontalIcon} i18n={i18n.more} />
);
export const WorkflowsIconComponent = (
	<TranslatedIconComponent icon={DataFlowIcon} i18n={i18n.workflows} />
);
export const CustomizeIconComponent = (
	<TranslatedIconComponent icon={CustomizeIcon} i18n={i18n.customize} />
);

//ensure that keys all match the menuId type in useMenuItems
export const iconByMenuId: { [key in menuId]: ReactElement<TranslatedComponentProps> } = {
	customHome: HomeIconComponent,
	yourWork: HomeIconComponent,
	companyHub: OfficeIconComponent,
	recent: ClockIconComponent,
	starred: StarIconComponent,
	spaces: GlobeIconComponent,
	apps: AppsIconComponent,
	goals: GoalIconComponent,
	topics: HashIconComponent,
	teams: TeamsIconComponent,
	drafts: EditIconComponent,
	tasks: TaskIconComponent,
	templates: TemplatesIconComponent,
	workflows: WorkflowsIconComponent,
	customize: CustomizeIconComponent,
};
