import { LoadablePaint, type LoadableType } from '@confluence/loadable';

import type { menuId } from '../useMenuItems';

import type { GlobalItemProps } from './globalItemProps';

const CustomHomeLoader: LoadableType<GlobalItemProps> = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GlobalItem-CustomHome" */ './CustomHomeItem'))
			.CustomHomeItem,
});

const YourWorkLoader: LoadableType<GlobalItemProps> = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GlobalItem-YourWork" */ './YourWorkItem'))
			.YourWorkItem,
});

const RecentLoader: LoadableType<GlobalItemProps> = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GlobalItem-Recent" */ './RecentItem')).RecentItem,
});

const StarredLoader: LoadableType<GlobalItemProps> = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GlobalItem-Starred" */ './StarredItem'))
			.StarredItem,
});

const CompanyHubLoader: LoadableType<GlobalItemProps> = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-GlobalItem-CompanyHub" */ './CompanyHubItem/CompanyHubItem'
			)
		).CompanyHubItem,
});

const SpacesLoader: LoadableType<GlobalItemProps> = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GlobalItem-Spaces" */ './SpacesItem')).SpacesItem,
});

const AppsLoader: LoadableType<GlobalItemProps> = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GlobalItem-Apps" */ './AppsItem/index')).AppsItem,
});

const GoalsLoader: LoadableType<GlobalItemProps> = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GlobalItem-Goals" */ './GoalsItem')).GoalsItem,
});

const TopicsLoader: LoadableType<GlobalItemProps> = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GlobalItem-Topics" */ './TopicsItem')).TopicsItem,
});

const TeamsLoader: LoadableType<GlobalItemProps> = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GlobalItem-Teams" */ './TeamsItem')).TeamsItem,
});

const DraftsLoader: LoadableType<GlobalItemProps> = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GlobalItem-Drafts" */ './DraftsItem')).DraftsItem,
});

const TasksLoader: LoadableType<GlobalItemProps> = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GlobalItem-Tasks" */ './TasksItem')).TasksItem,
});

const TemplatesLoader: LoadableType<GlobalItemProps> = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GlobalItem-Templates" */ './TemplatesItem'))
			.TemplatesItem,
});

const WorkflowsLoader: LoadableType<GlobalItemProps> = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GlobalItem-Workflows" */ './WorkflowsItem'))
			.WorkflowsItem,
});

const CustomizeSidebarLoader: LoadableType<GlobalItemProps> = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-GlobalItem-CustomizeSidebar" */ './CustomizeSidebarItem'
			)
		).CustomizeSidebarItem,
});

export const GlobalItemLoadables: { [key in menuId]?: LoadableType<any> } = {
	customHome: CustomHomeLoader,
	yourWork: YourWorkLoader,
	companyHub: CompanyHubLoader,
	recent: RecentLoader,
	starred: StarredLoader,
	spaces: SpacesLoader,
	apps: AppsLoader,
	goals: GoalsLoader,
	topics: TopicsLoader,
	teams: TeamsLoader,
	drafts: DraftsLoader,
	tasks: TasksLoader,
	templates: TemplatesLoader,
	workflows: WorkflowsLoader,
	customize: CustomizeSidebarLoader,
};
