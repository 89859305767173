import { useMemo, useContext } from 'react';
import memoize from 'lodash/memoize';

import {
	CONFLUENCE_PEOPLE_DIRECTORY_SEARCH,
	HOME,
	TEMPLATE_GALLERY,
	WORKFLOWS,
} from '@confluence/named-routes';
import { SPAViewContext } from '@confluence/spa-view-context';
import { useIsCompanyHubEnabled } from '@confluence/company-hub-utils/entry-points/useIsCompanyHubEnabled';
import { useViewUserProfile } from '@confluence/global-operations';
import { useWebItemLocation, SYSTEM_HEADER_LEFT } from '@confluence/web-item-location';
import { fg } from '@confluence/feature-gating';

export type menuId =
	| 'customHome'
	| 'yourWork'
	| 'companyHub'
	| 'recent'
	| 'starred'
	| 'spaces'
	| 'apps'
	| 'goals'
	| 'topics'
	| 'teams'
	| 'drafts'
	| 'tasks'
	| 'templates'
	| 'workflows'
	| 'customize';

export type menuItemDisplayDict = Record<menuId, boolean>;
export type partialMenuItemDisplayDict = Partial<menuItemDisplayDict>;
export type menuItemFilteredDict = Pick<configurationFields, 'menuId' | 'customizable'>[];
export type moreMenuItemFilteredDict = Pick<
	configurationFields,
	'menuId' | 'customizable' | 'href'
>[];

export type platformCustomizationObj = {
	typeId: menuId;
	config: {
		visible: boolean;
		position?: number; // this is tbd
	};
};
export type platformCustomizationArray = platformCustomizationObj[];

type configurationFields = {
	menuId: menuId;
	visibility: boolean;
	defaultVisibility: boolean;
	authenticationRequired: boolean;
	customizable: boolean;
	position: number;
	href?: string;
};

type configurationObject = Pick<
	configurationFields,
	'defaultVisibility' | 'authenticationRequired' | 'position' | 'customizable' | 'href'
>;

//this object lists all possible top-level items in Confluence Global Nav
const menuItemDefaultConfiguration: Record<menuId, configurationObject> = {
	customHome: {
		defaultVisibility: true,
		authenticationRequired: false,
		position: 0, // needed for the future when we either change defaults, or add / remove items that may already be customized / don't exist in user's customization
		customizable: false,
	},
	yourWork: {
		defaultVisibility: true,
		authenticationRequired: true,
		position: 1,
		customizable: false,
	},
	companyHub: {
		defaultVisibility: true,
		authenticationRequired: false,
		position: 2,
		customizable: false,
	},
	recent: {
		defaultVisibility: true,
		authenticationRequired: true,
		position: 3,
		customizable: true,
	},
	starred: {
		defaultVisibility: true,
		authenticationRequired: true,
		position: 4,
		customizable: true,
	},
	spaces: {
		defaultVisibility: true,
		authenticationRequired: false,
		position: 5,
		customizable: true,
	},
	apps: {
		defaultVisibility: true,
		authenticationRequired: false,
		position: 6,
		customizable: true,
	},
	goals: {
		//todo: where does this go?
		defaultVisibility: false,
		authenticationRequired: true,
		position: 7,
		customizable: true,
		href: '#',
	},
	topics: {
		//todo: where does this go?
		defaultVisibility: false,
		authenticationRequired: false,
		position: 8,
		customizable: true,
		href: '#',
	},
	teams: {
		defaultVisibility: false,
		authenticationRequired: true,
		position: 9,
		customizable: true,
		href: CONFLUENCE_PEOPLE_DIRECTORY_SEARCH.toUrl(),
	},
	drafts: {
		defaultVisibility: false,
		authenticationRequired: true,
		position: 10,
		customizable: true,
		href: HOME.toUrl({ view: 'drafts' }),
	},
	tasks: {
		defaultVisibility: false,
		authenticationRequired: true,
		position: 11,
		customizable: true,
		href: HOME.toUrl({ view: 'tasks' }),
	},
	templates: {
		defaultVisibility: false,
		authenticationRequired: false,
		position: 12,
		customizable: true,
		href: TEMPLATE_GALLERY.toUrl(),
	},
	workflows: {
		defaultVisibility: false,
		authenticationRequired: true,
		position: 13,
		customizable: true,
		href: WORKFLOWS.toUrl(),
	},
	customize: {
		defaultVisibility: false,
		authenticationRequired: true,
		position: 14,
		customizable: false,
	},
};

const defaultIterable: platformCustomizationArray = Object.entries(
	menuItemDefaultConfiguration,
).map(([key, value]) => ({
	typeId: key as menuId,
	config: {
		visible: value.defaultVisibility, // position is skipped because it's only used for mixin
	},
}));

const mixInDefaults = memoize((customizationArr?: platformCustomizationArray) => {
	const validatedCustomizationArr: platformCustomizationArray = [];
	const customizedDefaultItemIndices: number[] = [];

	if (customizationArr) {
		customizationArr.forEach((menuItem) => {
			const { typeId } = menuItem;
			const defaultExists = Boolean(menuItemDefaultConfiguration[typeId]);
			const position = defaultExists ? menuItemDefaultConfiguration[typeId].position : undefined;
			if (position) {
				customizedDefaultItemIndices.push(position);
				validatedCustomizationArr.push(menuItem);
			}
		});
		// remove all duplicate keys from Customization from the default menuItem array
		const deDupedDefaultIterable = defaultIterable.filter((_, index) => {
			return !customizedDefaultItemIndices.includes(index);
		});
		// return customization items first, and filtered defaults last
		return [...validatedCustomizationArr, ...deDupedDefaultIterable];
	} else {
		return defaultIterable;
	}
});

const createMenuItemObject = (key: menuId, config: configurationObject) => ({
	menuId: key,
	customizable: config.customizable,
	...(config.href && { href: config.href }),
});

// this method allows us to create any number of filtered lists in one or two loops.
// This would be quite good for SSR, and keeps all the configuration properties in a single place...
// but it's very different than what Jira does.
const createMenuItemLists = (
	isPermitted: boolean,
	customizationArr?: platformCustomizationArray,
	customFilters?: partialMenuItemDisplayDict,
) => {
	const globalMenuItems: menuItemFilteredDict = [];
	const moreMenuItems: moreMenuItemFilteredDict = [];

	const orderedItems = mixInDefaults(customizationArr);
	const customizeItemKey: menuId = 'customize';

	orderedItems.forEach((menuItem) => {
		const key = menuItem.typeId;
		const defaultConfig = menuItemDefaultConfiguration[key];

		if (
			!(defaultConfig.authenticationRequired && !isPermitted) &&
			customFilters?.[key] !== false &&
			key !== customizeItemKey
		) {
			const menuItemObj = createMenuItemObject(key, defaultConfig);
			(menuItem.config.visible ? globalMenuItems : moreMenuItems).push(menuItemObj);
		}
	});

	// customize sidebar item should be the last item of the more items menu
	// unless there are no items, then it should be in the global menu items
	if (customFilters?.[customizeItemKey] !== false) {
		const customizeItem = createMenuItemObject(
			customizeItemKey,
			menuItemDefaultConfiguration[customizeItemKey],
		);
		(moreMenuItems.length === 0 ? globalMenuItems : moreMenuItems).push(customizeItem);
	}

	return {
		moreMenuItems, // items hidden behind the more menu
		globalMenuItems, // when you want an ordered list of the elements that should render in the UI
	}; // you can add more if needed and it still only maps a single time (or twice if the customization object changed)
};

const generateLists = (
	isPermitted: boolean,
	customFilters: partialMenuItemDisplayDict,
	customizationArr?: platformCustomizationArray,
) => {
	const { globalMenuItems, moreMenuItems } = createMenuItemLists(
		isPermitted,
		customizationArr,
		customFilters,
	);
	return { globalMenuItems, moreMenuItems };
};

export const useMenuItems = (
	isPermitted: boolean,
	customizationArr?: platformCustomizationArray,
) => {
	const { loading: webItemsLoading, webItems } = useWebItemLocation({
		location: SYSTEM_HEADER_LEFT,
		allowedWebItems: [SPACE_DIRECTORY_KEY, PEOPLE_DIRECTORY_KEY],
	});
	const { isCompanyHubEntryPointEnabled } = useIsCompanyHubEnabled();
	const { canViewUserProfile: hasViewUserProfilePermission } = useViewUserProfile();
	const { homepageUri, frontCoverState, loading } = useContext(SPAViewContext);

	const isCustomHomeSet: boolean = !['/spa.action', '', 'homepageUri'].includes(homepageUri);
	const isSpacesVisible =
		!webItemsLoading && !!webItems.find((item) => item.completeKey === SPACE_DIRECTORY_KEY);
	const isPeopleVisible =
		!webItemsLoading &&
		(hasViewUserProfilePermission ||
			!!webItems.find((item) => item.completeKey === PEOPLE_DIRECTORY_KEY));
	const isCompanyHubVisible = !loading && isCompanyHubEntryPointEnabled && !!frontCoverState;

	return useMemo(() => {
		// these custom filters remove elements from the returned data structures based on application state and override defaults and customization store
		// eslint-disable-next-line confluence-feature-gating/inline-usage
		const customFilters: partialMenuItemDisplayDict = {
			customHome: isCustomHomeSet,
			companyHub: isCompanyHubVisible,
			spaces: isSpacesVisible,
			teams: isPeopleVisible,
			topics: false, // todo: remove when platform tells us what topics does / goes
			goals: false, // todo: remove when platform tells us what goals does / goes
			// Hide Drafts tab when live edit is enabled for the site
			// Hide Drafts, Tasks, and Templates for nav 4 beta
			drafts: !fg('confluence_live_pages_ab_test_opted_in') && !fg('confluence_nav_4_beta'),
			tasks: !fg('confluence_nav_4_beta'),
			templates: !fg('confluence_nav_4_beta'),
			workflows: fg('document_workflows'),
			customize: fg('confluence_nav_4_beta'),
		};

		return generateLists(isPermitted, customFilters, customizationArr);
	}, [
		isCustomHomeSet,
		isCompanyHubVisible,
		isSpacesVisible,
		isPeopleVisible,
		isPermitted,
		customizationArr,
	]);
};

export const SPACE_DIRECTORY_KEY =
	'com.atlassian.confluence.plugins.confluence-space-ia:header-spaces-link';
export const PEOPLE_DIRECTORY_KEY =
	'com.atlassian.confluence.plugins.confluence-space-ia:header-people-link';
